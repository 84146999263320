// import {
//   EventDispatcher
// } from 'three'

import * as THREE from 'three'
const _lineChange = { type: 'linechange', cur_line: null, cur_index: null, last_null: null, last_index: null};
const _select = { type: 'select', index: null, object: null }

class Interact extends THREE.EventDispatcher{
  domElement = null
  camera = null
  plane = null
  objs = null
  cur_index = null
  last_index = null
  onPointerMove = this.detectLine.bind(this)
  onClick = this.selectLine.bind(this)

  constructor(camera, domElement, plane, objs){
    super();
    this.camera = camera
    this.domElement = domElement
    this.plane = plane
    this.objs = objs
    this.domElement.addEventListener('pointermove', this.onPointerMove)
    this.domElement.addEventListener('click', this.onClick)
  }

  selectLine(ev){
    if(this.cur_index != null){
      _select.index = this.cur_index;
      _select.object = this.objs[this.cur_index]
      console.log(ev)
      this.dispatchEvent(_select)
    }
  }

  detectLine(ev){
    // console.log(1)
    const raycaster = new THREE.Raycaster()
    const pointer = new THREE.Vector2((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    raycaster.setFromCamera(pointer, this.camera);
    const intersects = raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0 && this.objs) {
      const intersect = intersects[0];
      let detect_flag = false;
      for(let i = 0; i < this.objs.length; i++){
        const item = this.objs[i].points
        if(this.objs[i].type == 'arc' || this.objs[i].type == 'circle' || this.objs[i].type == 'chamfer'){
          const min = Math.max(item[0].distanceToSquared(item[1]) / 2, 10) 
          for(let j = 0; j < item.length; j++){
            // console.log(item[j].distanceToSquared(new THREE.Vector2().fromArray(intersect.point.toArray())) )
            if(item[j].distanceToSquared(new THREE.Vector2().fromArray(intersect.point.toArray())) < min){
              console.log('close')
              this.cur_index = i;
              detect_flag = true
              break;
            }
          }
          if(detect_flag){
            break;
          }
        } else if(this.objs[i].type == 'line'){
          const startPoint = this.objs[i].points[0]
          const endPoint = this.objs[i].points[1]
          const dir1 = new THREE.Vector3().copy(endPoint).sub(startPoint).normalize()
          const dir2 = new THREE.Vector3().copy(startPoint).sub(endPoint).normalize()
          const ray1 = new THREE.Ray(startPoint, dir1)
          const ray2 = new THREE.Ray(endPoint, dir2)
          const dis1 = ray1.distanceSqToPoint(intersect.point)
          const dis2 = ray2.distanceSqToPoint(intersect.point)
          const dis = Math.min(dis1, dis2)
          const min2 = 10
          // console.log(dis)
          if(dis < min2){
            this.cur_index = i;
            detect_flag = true
            break;
          }
        }
      }
      if(!detect_flag){
        this.cur_index = null
      }
    } else{
      this.cur_index = null
    }
    if(this.cur_index != this.last_index){
      _lineChange.cur_index = this.cur_index;
      _lineChange.last_index = this.last_index;
      this.dispatchEvent(_lineChange)
      this.last_index = this.cur_index
    }
  }

  // addObj(obj){
  //   this.objs.push(obj)
  // }

  // removeObj(index){
  //   this.objs.splice(index, 1)
  // }
  updateObjs(objs){
    this.objs = objs
  }
  
}

export {Interact}