
import { defineComponent, ref } from 'vue'

export default defineComponent({
  // setup() {
  //   const isCollapse = ref(true)
  //   const handleOpen = (key, keyPath) => {
  //     console.log(key, keyPath)
  //   }
  //   const handleClose = (key, keyPath) => {
  //     console.log(key, keyPath)
  //   }
  //   return {
  //     isCollapse,
  //     handleOpen,
  //     handleClose,
  //   }
  // },
  props:{
    meshes:[]
  },
  data(){
    return {
      // meshes:[]
    }
  }
})
