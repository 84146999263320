import {
  BufferGeometry,
	Vector3,
  Camera,
	// Vector3,
  Raycaster,
  EventDispatcher
} from 'three'

const _finishEvent = { type: 'finish', start: null, end: null, points: null, mesh: null };

import * as THREE from 'three'
import { DRAW_MODE } from '@/constant/constant'

class LinePainter extends EventDispatcher{
  raycaster = null
  camera = null
  scene = null
  renderer = null
  domElement = null
  plane = null // 辅助平面
  enabled = false // 是否激活
  
  // centerPoint = null // 圆心
  line = null

  center = new THREE.Vector2() // 圆心坐标
  radius = 0 // 半径
  points = null
  
  step = 0

  startPoint = new THREE.Vector3()
  endPoint = new THREE.Vector3()
  
  clickHandler = this.draw.bind(this)
  moveHandler = this.onMove.bind(this)
  pointer = new THREE.Vector2()
  constructor(camera, domElement, scene, renderer, plane){
    super();
    this.camera = camera
    if(!domElement){
      this.domElement = document
    }
    this.domElement = domElement
    this.plane = plane
    this.scene = scene
    this.renderer = renderer
    this.raycaster = new Raycaster();
    // this.center = new Vector3();
  }
  draw(ev){
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0) {
      let geometry, material;
      const intersect = intersects[0];
      switch(this.step){
        case 0:
          this.startPoint.copy(intersect.point)
          // this.centerPoint.fromArray(intersect.point.toArray())
          const line = new THREE.LineCurve3(intersect.point, intersect.point)
          const points = line.getPoints(5);
          // console.log(points)
          geometry = new THREE.BufferGeometry().setFromPoints(points);
          material = new THREE.LineBasicMaterial({ color: 0xff0000 })
          this.line = new THREE.Line(geometry, material);
          this.scene.add(this.line)
          this.step = 1;
          break;
        case 1:
          this.scene.remove(this.center);
          this.scene.remove(this.line);
          delete this.center;
          this.center = null;
          _finishEvent.start = this.startPoint
          _finishEvent.end = this.endPoint
          _finishEvent.points = this.points
          _finishEvent.mesh = this.line
          this.dispatchEvent(_finishEvent)
          delete this.line;
          this.line = null;
          this.step = 0;
          // this.scene.remove(this.line)
          // this.startPoint.fromArray(intersect.point.toArray())
          // this.startAgl = Math.atan2(this.startPoint.y - this.centerPoint.y, this.startPoint.x - this.centerPoint.x)
          // this.radius =  this.startPoint.distanceTo(this.centerPoint)
          // const curve = new THREE.EllipseCurve(
          //   this.centerPoint.x, this.centerPoint.y,            // ax, aY
          //   10, 10,           // xRadius, yRadius
          //   0, 0,  // aStartAngle, aEndAngle
          //   false,            // aClockwise
          //   0                 // aRotation
          // );
          // const points2 = curve.getPoints(10);
          // geometry = new THREE.BufferGeometry().setFromPoints(points2);
          // material = new THREE.LineBasicMaterial({ color: 0xff0000 });
          // this.ellipse = new THREE.Line(geometry, material);
          // this.scene.add(this.ellipse)
          // console.log(this.ellipse)
          // // p = createPoint(intersect.point.x, intersect.point.y)
          // // scene.add(p)
          // this.step = 2;
          break;
        }
      }
      // console.log(this)
      console.log(this.step)
  }
  step0(intersect){

    console.log('step 1')
  }
  onMove(ev){
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0) {
      const intersect = intersects[0];
      switch(this.step){
        // const p = new THREE.Vector3(this.centerPoint.x, this.centerPoint.y, 0)
        // const line = new THREE.LineCurve3(p, intersect.point)
        // points = line.getPoints(5);
        // if (this.ellipse_line) {
        //   this.ellipse_line.geometry.dispose();
        //   geometry = new THREE.BufferGeometry().setFromPoints(points);
        //   this.ellipse_line.geometry = geometry;
        // }
        // break;
        case 1:
          this.endPoint.copy(intersect.point)
          const line = new THREE.LineCurve3(this.startPoint, this.endPoint)
          this.points = line.getPoints(1);
          const geometry = new THREE.BufferGeometry().setFromPoints(this.points);
          // const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
          // this.line = new THREE.Line(geometry, material);
          // this.scene.add(this.line)
          if(this.line){
            this.line.geometry.dispose()
            this.line.geometry = geometry
          }

          break;
      }
      this.render()
    }
  }
  createPoint(x = 0, y = 0) : THREE.Mesh{
    const geo = new THREE.RingGeometry( 0, 2, 32 );
    const material = new THREE.MeshBasicMaterial( { color: 0xff0000, side: THREE.DoubleSide } );
    const point = new THREE.Mesh(geo, material);
    point.position.set(x, y, 0)
    return point
  }
  update(): void{
    if(this.enabled){
      this.domElement.addEventListener('click', this.clickHandler)
      this.domElement.addEventListener('pointermove', this.moveHandler)
    } else{
      this.step = 0
      this.domElement.removeEventListener('click', this.clickHandler)
      this.domElement.removeEventListener('pointermove', this.moveHandler)
    }
  }
  render(): void{
    this.renderer.render(this.scene, this.camera)
  }
}

export {LinePainter}