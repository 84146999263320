import {
  BufferGeometry,
	Vector3,
  Camera,
	// Vector3,
  Raycaster,
  EventDispatcher
} from 'three'

const _finishEvent = { type: 'finish', center: null, radius: null, points: null, mesh: null };

import * as THREE from 'three'
import { DRAW_MODE } from '@/constant/constant'

class CirclePainter extends EventDispatcher{
  raycaster = null
  camera = null
  scene = null
  renderer = null
  domElement = null
  plane = null // 辅助平面
  enabled = false // 是否激活
  
  centerPoint = null // 圆心
  ellipse = null

  center = new THREE.Vector2() // 圆心坐标
  radius = 0 // 半径
  points = null
  
  step = 0
  
  clickHandler = this.draw.bind(this)
  moveHandler = this.onMove.bind(this)
  pointer = new THREE.Vector2()
  constructor(camera, domElement, scene, renderer, plane){
    super();
    this.camera = camera
    if(!domElement){
      this.domElement = document
    }
    this.domElement = domElement
    this.plane = plane
    this.scene = scene
    this.renderer = renderer
    this.raycaster = new Raycaster();
    // this.center = new Vector3();
  }
  draw(ev){
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0) {
      const intersect = intersects[0];
      switch(this.step){
        case 0:
          // this.step0(intersect);
          // this.center.copy(intersect.point)
          const curve = new THREE.EllipseCurve(
            this.pointer.x, this.pointer.y,            // ax, aY
            0, 0,
            0, 0,
            false,
            0
          );
          const points = curve.getPoints(5);
          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
          this.ellipse = new THREE.Line(geometry, material);
          this.scene.add(this.ellipse)
          this.center.fromArray(intersect.point.toArray())
          console.log(this.center)
          const x = intersect.point.x
          const y = intersect.point.y
          this.centerPoint = this.createPoint(x, y)
          this.scene.add(this.centerPoint)
          this.render()
          this.step++
          break;
        case 1:
          this.scene.remove(this.centerPoint);
          this.scene.remove(this.ellipse);
          delete this.centerPoint;
          this.centerPoint = null;
          _finishEvent.center = this.center
          _finishEvent.radius = this.radius
          _finishEvent.points = this.points
          _finishEvent.mesh = this.ellipse
          this.dispatchEvent(_finishEvent)
          delete this.ellipse;
          this.ellipse = null;
          // scene.remove(ellipse);
          this.step = 0;
          break;
        }
      }
      // console.log(this)
      console.log(this.step)
  }
  step0(intersect){

    console.log('step 1')
  }
  onMove(ev){
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0) {
      const intersect = intersects[0];
      switch(this.step){
        case 1:
          const curPoint = new THREE.Vector2()
          curPoint.fromArray(intersect.point.toArray())
          this.radius = curPoint.distanceTo(this.center)
          const curve = new THREE.EllipseCurve(
            this.center.x, this.center.y,            // ax, aY
            this.radius, this.radius,           // xRadius, yRadius
            0, 2 * Math.PI,  // aStartAngle, aEndAngle
            false,            // aClockwise
            0                 // aRotation
          );
          const points = curve.getPoints(100);
          this.points = points;
          const geometry = new THREE.BufferGeometry().setFromPoints(points);

          if (this.ellipse) {
            this.ellipse.geometry.dispose();
            this.ellipse.geometry = geometry;
          }
          this.render()
        }
    }
  }
  createPoint(x = 0, y = 0) : THREE.Mesh{
    const geo = new THREE.RingGeometry( 0, 2, 32 );
    const material = new THREE.MeshBasicMaterial( { color: 0xff0000, side: THREE.DoubleSide } );
    const point = new THREE.Mesh(geo, material);
    point.position.set(x, y, 0)
    return point
  }
  update(): void{
    if(this.enabled){
      this.domElement.addEventListener('pointerdown', this.clickHandler)
      this.domElement.addEventListener('pointermove', this.moveHandler)
    } else{
      this.step = 0
      this.domElement.removeEventListener('pointerdown', this.clickHandler)
      this.domElement.removeEventListener('pointermove', this.moveHandler)
    }
  }
  render(): void{
    this.renderer.render(this.scene, this.camera)
  }
}

export {CirclePainter}