import {
  BufferGeometry,
	Vector3,
  Camera,
	// Vector3,
  Raycaster,
  EventDispatcher
} from 'three'

const _finishEvent = { type: 'finish', center: null, radius: null, points: null, mesh: null };

import * as THREE from 'three'
import { DRAW_MODE } from '@/constant/constant'

class ArcPainter extends EventDispatcher{
  raycaster = null
  camera = null
  scene = null
  renderer = null
  domElement = null
  plane = null // 辅助平面
  enabled = false // 是否激活
  mode = 'CENTER' // 方式 'CENTER' 'POINT'
  
  center = null // 圆心
  ellipse = null
  ellipse_line = null // 辅助半径线

  centerPoint = new THREE.Vector3() // 圆心坐标
  startPoint = new THREE.Vector3()
  endPoint = new THREE.Vector3()
  startAgl = null // 起始角度
  endAgl = null
  radius = 0 // 半径
  points = null
  
  step = 0
  
  clickHandler = this.draw.bind(this)
  moveHandler = this.onMove.bind(this)
  pointer = new THREE.Vector2()
  constructor(camera, domElement, scene, renderer, plane){
    super();
    this.camera = camera
    if(!domElement){
      this.domElement = document
    }
    this.domElement = domElement
    this.plane = plane
    this.scene = scene
    this.renderer = renderer
    this.raycaster = new Raycaster();
    // this.centerPoint = new Vector3();
  }
  draw(ev){
    if(this.mode == 'CENTER'){
      this.drawByCenter(ev)
    } else if(this.mode == 'POINT'){
      this.drawByPoint(ev)
    }
  }

  drawByCenter(ev){
    console.log('arc')
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    let geometry, material;
    if (intersects.length > 0) {
      const intersect = intersects[0];
      switch (this.step) {
        case 0:
          // this.pointer.copy(intersect.point)
          this.center = this.createPoint(intersect.point.x, intersect.point.y)
          this.scene.add(this.center)
          this.centerPoint.fromArray(intersect.point.toArray())
          const line = new THREE.LineCurve3(intersect.point, intersect.point)
          const points = line.getPoints(5);
          // console.log(points)
          geometry = new THREE.BufferGeometry().setFromPoints(points);
          material = new THREE.LineBasicMaterial({ color: 0xff0000 })
          this.ellipse_line = new THREE.Line(geometry, material);
          this.scene.add(this.ellipse_line)
          this.step = 1;
          break;
        case 1:
          this.scene.remove(this.ellipse_line)
          this.startPoint.fromArray(intersect.point.toArray())
          this.startAgl = Math.atan2(this.startPoint.y - this.centerPoint.y, this.startPoint.x - this.centerPoint.x)
          this.radius =  this.startPoint.distanceTo(this.centerPoint)
          const curve = new THREE.EllipseCurve(
            this.centerPoint.x, this.centerPoint.y,            // ax, aY
            10, 10,           // xRadius, yRadius
            0, 0,  // aStartAngle, aEndAngle
            false,            // aClockwise
            0                 // aRotation
          );
          const points2 = curve.getPoints(10);
          geometry = new THREE.BufferGeometry().setFromPoints(points2);
          material = new THREE.LineBasicMaterial({ color: 0xff0000 });
          this.ellipse = new THREE.Line(geometry, material);
          this.scene.add(this.ellipse)
          console.log(this.ellipse)
          // p = createPoint(intersect.point.x, intersect.point.y)
          // scene.add(p)
          this.step = 2;
          break;
        case 2:
          this.scene.remove(this.center);
          this.scene.remove(this.ellipse);
          delete this.center;
          this.center = null;
          _finishEvent.center = this.centerPoint
          _finishEvent.radius = this.radius
          _finishEvent.points = this.points
          _finishEvent.mesh = this.ellipse
          this.dispatchEvent(_finishEvent)
          delete this.ellipse;
          this.ellipse = null;
          // scene.remove(ellipse);
          this.step = 0;
          break;
        }
      }
      // console.log(this)
      console.log(this.step)
  }
  drawByPoint(ev){
    // console.log('to be continued')
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    let geometry, material;
    if (intersects.length > 0) {
      const intersect = intersects[0];
      switch (this.step) {
        case 0:
          // this.pointer.copy(intersect.point)
          // this.center = this.createPoint(intersect.point.x, intersect.point.y)
          // this.scene.add(this.center)
          this.startPoint.fromArray(intersect.point.toArray())
          const line = new THREE.LineCurve3(new THREE.Vector3(0,0,0), intersect.point)
          const points = line.getPoints(1);
          // console.log(points)
          geometry = new THREE.BufferGeometry().setFromPoints(points);
          material = new THREE.LineDashedMaterial( { color: 0xffff00, dashSize: 3, gapSize: 2, scale: 0.3 } )
          this.ellipse_line = new THREE.Line(geometry, material);
          this.ellipse_line.computeLineDistances()
          this.scene.add(this.ellipse_line)
          this.step = 1;
          this.render()
          break;
        case 1:
          this.scene.remove(this.ellipse_line)
          // this.pointer.copy(intersect.point)
          this.endPoint.fromArray(intersect.point.toArray())
          this.step = 2;
          break;
        case 2:
          this.scene.remove(this.center);
          this.scene.remove(this.ellipse);
          delete this.center;
          this.center = null;
          _finishEvent.center = this.centerPoint
          _finishEvent.radius = this.radius
          _finishEvent.points = this.points
          _finishEvent.mesh = this.ellipse
          this.dispatchEvent(_finishEvent)
          delete this.ellipse;
          this.ellipse = null;
          // scene.remove(ellipse);
          this.step = 0;
          break;
          
      }
      // this.render()
    }
  }
  step0(intersect){

    console.log('step 1')
  }
  onMove(ev){
    if(this.mode == 'CENTER'){
      this.moveByCenter(ev)
    } else if(this.mode == 'POINT'){
      this.moveByPoint(ev)
    }
  }
  moveByCenter(ev){
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0) {
      const intersect = intersects[0];
      let points,geometry;
      switch (this.step) {
        case 1:
          const p = new THREE.Vector3(this.centerPoint.x, this.centerPoint.y, 0)
          const line = new THREE.LineCurve3(p, intersect.point)
          points = line.getPoints(5);
          if (this.ellipse_line) {
            this.ellipse_line.geometry.dispose();
            geometry = new THREE.BufferGeometry().setFromPoints(points);
            this.ellipse_line.geometry = geometry;
          }
          break;
        case 2:
          this.endPoint.fromArray(intersect.point.toArray())
          this.endAgl = Math.atan2(this.endPoint.y - this.centerPoint.y, this.endPoint.x - this.centerPoint.x)
          const curve = new THREE.EllipseCurve(
            this.centerPoint.x, this.centerPoint.y,            // ax, aY
            this.radius, this.radius,           // xRadius, yRadius
            this.startAgl, this.endAgl,  // aStartAngle, aEndAngle
            true,            // aClockwise
            0                 // aRotation
          );
          points = curve.getPoints(50);
          this.points = points;
          geometry = new THREE.BufferGeometry().setFromPoints(points);

          if (this.ellipse) {
            this.ellipse.geometry.dispose();
            this.ellipse.geometry = geometry;
          }
          break;
          
        }
        this.render()
    }
  }
  moveByPoint(ev){
    this.pointer.set((ev.clientX / window.innerWidth) * 2 - 1, -(ev.clientY / window.innerHeight) * 2 + 1);
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects([this.plane]);
    if (intersects.length > 0) {
      const intersect = intersects[0];
      let points,geometry;
      // console.log('point!')
      switch (this.step) {
        case 1:
          const p = new THREE.Vector3(this.startPoint.x, this.startPoint.y, 0)
          const line = new THREE.LineCurve3(p, intersect.point)
          points = line.getPoints(2);
          if (this.ellipse_line) {
            this.ellipse_line.geometry.dispose();
            geometry = new THREE.BufferGeometry().setFromPoints(points);
            this.ellipse_line.geometry = geometry;
            this.ellipse_line.computeLineDistances()
          }
          break;
        case 2:
          // this.pointer.copy(intersect.point)
          const point = new THREE.Vector3();
          point.copy(intersect.point)
          const tempP = new THREE.Vector3().copy(this.startPoint)
          tempP.add(this.endPoint).divideScalar(2)
          const dir1 = new THREE.Vector3(this.endPoint.y - this.startPoint.y, this.startPoint.x - this.endPoint.x , 0).normalize()
          const ray1 = new THREE.Ray(tempP, dir1)
          const dir2 = new THREE.Vector3(this.startPoint.y - this.endPoint.y, this.endPoint.x - this.startPoint.x, 0).normalize()
          const ray2 = new THREE.Ray(tempP, dir2)
          // console.log(ray1.distanceSqToPoint(point))
          // if(ray1.distanceSqToPoint(point) > 0){
            ray1.closestPointToPoint(point, this.centerPoint)
          // } else{
            if(this.centerPoint.equals(tempP)){

              ray2.closestPointToPoint(point, this.centerPoint)
            }
          // }
          console.log(dir1)
          console.log(this.centerPoint)
          // point.fromArray(intersect.point.toArray())
          // this.center = this.createPoint(intersect.point.x, intersect.point.y)


          this.startAgl = Math.atan2(this.startPoint.y - this.centerPoint.y, this.startPoint.x - this.centerPoint.x)
          this.endAgl = Math.atan2(this.endPoint.y - this.centerPoint.y, this.endPoint.x - this.centerPoint.x)
          this.radius =  this.startPoint.distanceTo(this.centerPoint)
          const curve = new THREE.EllipseCurve(
            this.centerPoint.x, this.centerPoint.y,            // ax, aY
            this.radius, this.radius,           // xRadius, yRadius
            this.startAgl, this.endAgl,  // aStartAngle, aEndAngle
            true,            // aClockwise
            0                 // aRotation
          );
          points = curve.getPoints(50);
          this.points = points;
          geometry = new THREE.BufferGeometry().setFromPoints(points);
          const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
          
          if(!this.center){
            this.center = this.createPoint(this.centerPoint.x, this.centerPoint.y)
            this.scene.add(this.center)
          } else{
            this.scene.remove(this.center)
            this.center = this.createPoint(this.centerPoint.x, this.centerPoint.y)
            this.scene.add(this.center)
          }
          
          if (this.ellipse) {
            this.ellipse.geometry.dispose();
            this.ellipse.geometry = geometry;
          } else{
            this.ellipse = new THREE.Line(geometry, material);
            this.scene.add(this.ellipse)
          }
          break;
          // this.centerPoint = 
      }
    }
    this.render()
  }
  createPoint(x = 0, y = 0) : THREE.Mesh{
    const geo = new THREE.RingGeometry( 0, 2, 32 );
    const material = new THREE.MeshBasicMaterial( { color: 0xff0000, side: THREE.DoubleSide } );
    const point = new THREE.Mesh(geo, material);
    point.position.set(x, y, 0)
    return point
  }
  update(): void{
    if(this.enabled){
      this.domElement.addEventListener('click', this.clickHandler)
      this.domElement.addEventListener('pointermove', this.moveHandler)
    } else{
      this.step = 0
      this.domElement.removeEventListener('click', this.clickHandler)
      this.domElement.removeEventListener('pointermove', this.moveHandler)
    }
  }
  render(): void{
    this.renderer.render(this.scene, this.camera)
  }
}

export {ArcPainter}