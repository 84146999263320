
import { defineComponent, markRaw } from 'vue'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { CirclePainter } from '../js/CirclePainter'
import { ArcPainter } from '../js/ArcPainter'
import { LinePainter } from '../js/LinePainter'
import { SelfCircle } from '../js/SelfCircle'
import { SelfArc } from '../js/SelfArc'
import { SelfLine } from '../js/SelfLine'
import Nav from './Nav.vue';
import SideBar from './SideBar.vue';
import { Interact } from '../js/interact'
import { ChamferPainter } from '@/js/ChamferPainter'
import { SelfChamfer } from '../js/SelfChamfer'

export default defineComponent({
  // setup() {
    
  // },
  components:{
    Nav, SideBar
  },
  // props:{
  //   // curDrawMode:{
  //   //   type: String,
  //   //   default: 'SELECT'
  //   // }
  // },
  data(){
    return {
      scene: '',
      renderer: '',
      camera: '',
      mesh_plane_assist: '',

      grid_size: 100,
      selfCircle: '',

      points: [],

      curDrawMode: '',
      objs:[],

      intersect: '',

      circlePainter: '',
      linePainter: ''
      
    }
  },
  methods:{
    init(){
      this.scene = markRaw(new THREE.Scene())
      this.renderer = markRaw(new THREE.WebGLRenderer())
      this.renderer.setSize(window.innerWidth, window.innerHeight)
      this.camera = markRaw(new THREE.OrthographicCamera( window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 0.1, 10000 ))
      this.camera.position.set(0,0,100)
      this.$el.appendChild(this.renderer.domElement)
    },
    initLight(){
            // 点光
      const point = new THREE.PointLight(0xffffff);
      point.position.set(0, 600, 1500);
      this.scene.add(point);
      // 环境光
      const ambient = new THREE.AmbientLight(0x444444);
      this.scene.add(ambient);
    },
    initHelper(){
      // const plane = new THREE.Plane(new THREE.Vector3(0,0,1))
      // const PlaneHelper = new THREE.PlaneHelper(plane)
      const gridHelper = new THREE.GridHelper( 3000, 100 )
      gridHelper.rotation.x = Math.PI / 2

      const geo_plane_assist = new THREE.PlaneGeometry(3000, 3000)
      const material_plane_assist = new THREE.MeshBasicMaterial({ color: 0x000000, opacity: 0, transparent: true, side: THREE.DoubleSide })
      this.mesh_plane_assist = markRaw(new THREE.Mesh(geo_plane_assist, material_plane_assist))
      this.scene.add(this.mesh_plane_assist)

      this.scene.add(gridHelper)
    },
    initOrbit(){
      this.orbit = markRaw(new OrbitControls( this.camera, this.renderer.domElement ))
      this.orbit.update();
      this.orbit.enableRotate = false;
      this.orbit.addEventListener( 'change', this.render )
    },
    initCirclePainter(){
      this.circlePainter = new CirclePainter(this.camera, this.renderer.domElement, this.scene, this.renderer, this.mesh_plane_assist)
      this.circlePainter.addEventListener('finish', (ev=>{
        this.points.push(ev.points)
        const selfCircle = new SelfCircle(ev.center, ev.radius, ev.points)
        this.scene.add(selfCircle)
        selfCircle.setSelected()
        this.selfCircle = selfCircle
        this.render()
        console.log(ev)
        this.objs.push(markRaw(selfCircle))
        this.interact.updateObjs(this.objs)
        // this.$emit('created',selfCircle)
        // this.interact.objs.push(selfCircle)
        this.meshes
      }).bind(this))
    },
    initArcPainter(){
      this.arcPainter = new ArcPainter(this.camera, this.renderer.domElement, this.scene, this.renderer, this.mesh_plane_assist)
      this.arcPainter.addEventListener('finish', (ev=>{
        this.points.push(ev.points)
        const selfArc = new SelfArc(ev.center, ev.radius, ev.points)
        this.scene.add(selfArc)
        selfArc.setSelected()
        this.selfArc = selfArc
        this.render()
        console.log(ev)
        this.objs.push(markRaw(selfArc))
        this.interact.updateObjs(this.objs)
        this.meshes
      }).bind(this))
    },
    initLinePainter(){
      this.linePainter = new LinePainter(this.camera, this.renderer.domElement, this.scene, this.renderer, this.mesh_plane_assist)
      this.linePainter.addEventListener('finish', (ev=>{
        this.points.push(ev.points)
        const selfLine = new SelfLine(ev.start, ev.end, ev.points)
        this.scene.add(selfLine)
        selfLine.setSelected()
        this.selfLine = selfLine
        this.render()
        console.log(ev)
        this.objs.push(markRaw(selfLine))
        this.interact.updateObjs(this.objs)
        // this.$emit('created',selfLine)
        // this.interact.objs.push(selfLine)
        this.meshes
      }).bind(this))
    },
    initChamferPainter(){
      this.chamferPainter = new ChamferPainter(this.camera, this.renderer.domElement, this.scene, this.renderer, this.mesh_plane_assist, this.interact)
      this.chamferPainter.addEventListener('finish', (ev => {
        this.points.splice(this.points[ev.line1_index])
        this.points.splice(this.points[ev.line2_index])
        this.points.push(ev.points)
        this.scene.remove(this.objs[ev.line1_index])
        this.scene.remove(this.objs[ev.line2_index])
        this.objs.splice(ev.line1_index)
        this.objs.splice(ev.line2_index)
        const selfChamfer = new SelfChamfer(0, 0, ev.points)
        this.scene.add(selfChamfer)
        selfChamfer.setSelected()
        this.selfChamfer = selfChamfer
        this.render()
        this.objs.push(markRaw(selfChamfer))
        this.interact.updateObjs(this.objs)
      }).bind(this))
    },
    render(){
      this.renderer.render(this.scene, this.camera)
    },
    changeMode(mode){
      // console.log(ev, tar)
      this.curDrawMode=mode
    },
    // test(ev){
    //   console.log(ev)
    //   this.meshes.push(ev)
    // }
  },
  mounted(){
    this.init()
    this.initLight()
    this.initHelper()
    this.initOrbit()
    this.initCirclePainter()
    this.initArcPainter()
    this.initLinePainter()
    this.interact = new Interact(this.camera, this.renderer.domElement, this.mesh_plane_assist, this.meshes)
    this.initChamferPainter()
    console.log(this.scene)
    this.render()
    console.log(this.interact)
    this.interact.addEventListener('linechange', (ev=>{
      ev.last_index != null && this.objs[ev.last_index].resetSelected();
      ev.cur_index != null && this.objs[ev.cur_index].setSelected();
      this.render()
    }).bind(this))

    
    
    // this.circlePainter.enabled = true
    // this.circlePainter.update()

  },
  watch:{
    curDrawMode(after){
      if(after == 'CIRCLE'){
        this.circlePainter.enabled = true
        this.circlePainter.update()
        this.arcPainter.enabled = false
        this.arcPainter.update()
        this.linePainter.enabled = false
        this.linePainter.update()
      } else if(after == 'ARCWITHCENTER'){
        this.circlePainter.enabled = false
        this.circlePainter.update()
        this.arcPainter.mode = 'CENTER'
        this.arcPainter.enabled = true
        this.arcPainter.update()
        this.linePainter.enabled = false
        this.linePainter.update()
      }else if(after == 'ARCWITHPOINT'){
        this.circlePainter.enabled = false
        this.circlePainter.update()
        this.arcPainter.mode = 'POINT'
        this.arcPainter.enabled = true
        this.arcPainter.update()
        this.linePainter.enabled = false
        this.linePainter.update()
      } else if(after == 'LINE'){
        this.circlePainter.enabled = false
        this.circlePainter.update()
        this.arcPainter.enabled = false
        this.arcPainter.update()
        this.linePainter.enabled = true
        this.linePainter.update()
      } else if(after == 'CHAMFER'){
        this.circlePainter.enabled = false
        this.circlePainter.update()
        this.arcPainter.enabled = false
        this.arcPainter.update()
        this.linePainter.enabled = false
        this.linePainter.update()
        this.chamferPainter.enabled = true
        this.chamferPainter.update()
      } else if(after == 'SETTING_OUT'){
        this.circlePainter.enabled = false
        this.circlePainter.update()
        this.arcPainter.enabled = false
        this.arcPainter.update()
        this.linePainter.enabled = false
        this.linePainter.update()
        this.chamferPainter.enabled = false
        this.chamferPainter.update()
        this.interact.addEventListener('select', ev => {

          const curve = new THREE.EllipseCurve(
            -10, 0,            // ax, aY
            10, 10,           // xRadius, yRadius
            0, 2 * Math.PI,  // aStartAngle, aEndAngle
            false,            // aClockwise
            0                 // aRotation
          );
          const points2 = curve.getPoints(50);

          const length = 12, width = 8;
          const shape = new THREE.Shape(points2);
          // shape.moveTo( 0,0 );
          // shape.lineTo( 0, width );
          // shape.lineTo( length, width );
          // shape.lineTo( length, 0 );
          // shape.lineTo( 0, 0 );

          // const curve = new THREE.SplineCurve(ev.object.points)
          const group = new THREE.Group()
          for(let i = 0; i < ev.object.points.length - 1; i++){
            const p1 = new THREE.Vector3(ev.object.points[i].x, ev.object.points[i].y, 0)
            const p2 = new THREE.Vector3(ev.object.points[i + 1].x, ev.object.points[i + 1].y, 0)
            const extrudeSettings = {
              steps: 2,
              // depth: 16,
              bevelEnabled: true,
              bevelThickness: 1,
              bevelSize: 1,
              bevelOffset: 0,
              extrudePath : new THREE.LineCurve3(p1, p2),
              bevelSegments: 1
            };
  
            const geometry = new THREE.ExtrudeGeometry( shape, extrudeSettings );
            const material = new THREE.MeshLambertMaterial( { color: 0x556600 } );
            const mesh = new THREE.Mesh( geometry, material ) ;
            // group.add(mesh)
            this.scene.add(mesh)
            this.render()
          }
          this.scene.add( group );
        })
      }
      console.log(after)
      
    }
  }
})
