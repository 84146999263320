import * as THREE from 'three'
const NORMAL_MATERIAL = new THREE.LineBasicMaterial({color:0xff0000})
const SELECTED_MATERIAL = new THREE.LineBasicMaterial({color: 0xffff00})


class SelfArc extends THREE.Object3D {
  selected = false
  center = new THREE.Vector2()
  radius = 0
  points = []
  mesh = null
  type = 'arc'
  constructor(center, radius, points) {
    super();
    this.points = points
    this.center.copy(center)
    this.radius = radius
    this.mesh = this.createCircle(points)
    this.add(this.mesh)
    // this.type='circle'
  }
  createCircle(points){
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const material = NORMAL_MATERIAL;
    return new THREE.Line(geometry, material)
  }
  setSelected(){
    this.selected = true
    this.mesh.material = SELECTED_MATERIAL
  }
  resetSelected(){
    this.selected = false
    this.mesh.material = NORMAL_MATERIAL
  }
}

export {SelfArc}