import {
  BufferGeometry,
	Vector3,
  Camera,
	// Vector3,
  Raycaster,
  EventDispatcher
} from 'three'

const _finishEvent = { type: 'finish',  points:null, line1_index: null, line2_index: null};

import * as THREE from 'three'
import { DRAW_MODE } from '@/constant/constant'
import { Interact } from './interact'

class ChamferPainter extends EventDispatcher{
  raycaster = null
  camera = null
  scene = null
  renderer = null
  domElement = null
  plane = null // 辅助平面
  enabled = false // 是否激活
  interact = null

  line1 = new THREE.Line3()
  line2 = new THREE.Line3()
  points = null

  step = 0

  onSelectHandler = this.draw.bind(this)

  constructor(camera, domElement, scene, renderer, plane, interact){
    super();
    this.camera = camera
    if(!domElement){
      this.domElement = document
    }
    this.domElement = domElement
    this.plane = plane
    this.scene = scene
    this.renderer = renderer
    this.raycaster = new Raycaster();
    this.interact = interact

  }

  draw(ev){
    switch(this.step){
      case 0:
        console.log(ev);
        // if(this.line1 == null){
        this.line1.set(ev.object.points[0], ev.object.points[1])
        _finishEvent.line1_index = ev.index
        this.step = 1;
        // }
        break;
      case 1:
        const temp = new THREE.Line3(ev.object.points[0], ev.object.points[1])
        if(!temp.equals(this.line1)){
          _finishEvent.line2_index = ev.index
          console.log(ev);
          this.line2.copy(temp)

          const theta1 = Math.atan2(this.line1.end.y - this.line1.start.y, this.line1.end.x - this.line1.start.x);
          const theta2 = Math.atan2(this.line2.end.y - this.line2.start.y, this.line2.end.x - this.line2.start.x);
          const radius = 30;
          const theta = (theta1 + theta2) / 2;
          const theta0 = (theta1 - theta2) / 2;
          const dir = new THREE.Vector3(Math.cos(theta), Math.sin(theta), 0);
          const a1 = this.line1.end.y - this.line1.start.y;
          const b1 = this.line1.start.x - this.line1.end.x;
          const c1 = this.line1.end.x * this.line1.start.y - this.line1.start.x * this.line1.end.y;
          const a2 = this.line2.end.y - this.line2.start.y;
          const b2 = this.line2.start.x - this.line2.end.x;
          const c2 = this.line2.end.x * this.line2.start.y - this.line2.start.x * this.line2.end.y;
          if(theta1 != theta2){
            const x = (c1 * b2 - c2 * b1) / (a2 * b1 - a1 * b2);
            const y = (c1 * a2 - c2 * a1) / (a1 * b2 - a2 * b1);
            const cross = new THREE.Vector3(x, y, 0)
            const ray = new THREE.Ray(cross, dir);
            // const ray1 = new THREE.Ray(cross, new THREE.Vector3().copy(this.line1.end).sub(cross).normalize())
            // const ray2 = new THREE.Ray(cross, new THREE.Vector3().copy(this.line2.end).sub(cross).normalize())
            // const cross1 = new THREE.Vector3()
            // const cross2 = new THREE.Vector3()
            const dis = radius / Math.sin(theta0);
            const center = new THREE.Vector3();
            ray.at(dis,center);
            // ray1.closestPointToPoint(center, cross1)
            // ray2.closestPointToPoint(center, cross2)
            const curve = new THREE.EllipseCurve(
              center.x, center.y,            // ax, aY
              radius, radius,
              Math.PI / 2 + theta1, Math.PI * 3 / 2 + theta2,
              false,
              0
            );
            // const curve2 = new THREE.EllipseCurve(
            //   center.x, center.y,            // ax, aY
            //   radius, radius,
            //   // 30,30,
            //   0, 6.28,
            //   false,
            //   0
            // );
            const points = curve.getPoints(50);
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            // const points2 = curve2.getPoints(50);
            // const geometry2 = new THREE.BufferGeometry().setFromPoints(points2);
            const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
            const chamfer = new THREE.Line(geometry, material);
            // const chamfer2 = new THREE.Line(geometry2, material);
            // this.scene.add(chamfer)
            // this.scene.add(chamfer2)
            // this.scene.remove(this.line1)
            // this.scene.remove(this.line2)
            console.log(center)
            points.unshift(new THREE.Vector2().fromArray(this.line1.end.toArray()))
            points.push(new THREE.Vector2().fromArray(this.line2.end.toArray()))
            _finishEvent.points = points
            this.dispatchEvent(_finishEvent)
          }

          // const ray = new THREE.Ray
          console.log(theta1, theta2)
          this.step = 0;
        }
        break;
    }
  }

  update(): void{
    if(this.enabled){
      // this.domElement.addEventListener('click', this.clickHandler)
      // this.domElement.addEventListener('pointermove', this.moveHandler)
      this.interact.addEventListener('select', this.onSelectHandler)
    } else{
      this.step = 0
      // this.domElement.removeEventListener('click', this.clickHandler)
      // this.domElement.removeEventListener('pointermove', this.moveHandler)
      this.interact.removeEventListener('select', this.onSelectHandler)
    }
  }
  render(): void{
    this.renderer.render(this.scene, this.camera)
  }
}

export {ChamferPainter}