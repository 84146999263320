
import { defineComponent } from 'vue'
import { DRAW_MODE_ENUM, DRAW_MODE } from '../constant/constant'

export default defineComponent({
  // setup() {
  
  // },
  data(){
    return {
      curMode: 'SELECT',
      draw_mode_enum : DRAW_MODE_ENUM,
      draw_mode: DRAW_MODE
    }
  },
})
