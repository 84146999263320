import {
  Vector2,
  Vector3
} from 'three'

// 图形种类
enum Type {
  Line,
  Arc,
  Polymer
}

interface baseConfig{
  points: Array<Vector3>
  type: Type
} 

export interface lineConfig extends baseConfig{
  start: Vector2
  end: Vector2
}

export interface arcConfig extends baseConfig{
  center: Vector2
  startAgl: number
  endAgl: number
}

// function fun(){

//   let test = {
//     points: [],
//     type: Type.Arc
//   }
//   if(){
  
//   }
// }


// export interface circleConfig extends baseConfig{
//   // startAgl = Math.PI
// }
