
import { defineComponent } from 'vue';
import Canvas from './Canvas.vue';




export default defineComponent({
  name: 'HelloWorld',
  components: { Canvas },
  props: {
    msg: String,
  },
  data(){
    return {

    }
  },
  methods:{

  },
  mounted(){
    // const Interact = new Interse
  }
});
